import React from 'react'
import styled from 'styled-components'
import karsten from '../images/karsten.gif'
import { LinkButton, LogoCircle, Seo, Typography } from '../components/utils'
import useMedia from 'use-media'

export default function SomethingWrongPage() {
  const isDesktop = useMedia({ minWidth: 768 })
  return (
    <>
      <Seo title="Etwas lief schief" />
      <Container>
        {Math.random() > 0.75 ? (
          <>
            <img
              src={karsten}
              alt="A men running and hitting a table and a wall"
              srcSet={karsten}
              width="320px"
            />
            <Typography variant="h1">Karsten lief schief</Typography>
          </>
        ) : (
          <>
            <Logo />
            <Typography variant="h1">Etwas lief schief</Typography>
          </>
        )}
        <Typography>
          Der ghostbuilder kann mit dieser Eingabe leider keinen PC
          zusammenstellen.
          {isDesktop && <br />} Das kann an <b>schlechter Teileverfügbarkeit</b>
          , <b>zu hohen Preisen</b> oder
          {isDesktop && <br />} <b>zu detaillierten Einstellungen</b> liegen.
        </Typography>
        <LinkButton to="/builder">zurück zum Builder</LinkButton>
      </Container>
    </>
  )
}

const Container = styled.section`
  padding: 0;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  row-gap: 1rem;
  text-align: center;

  p {
    display: block;
    max-width: 420px;
  }

  @media (min-width: 768px) {
    p {
      max-width: 608px;
    }
  }
`
const Logo = styled(LogoCircle)`
  height: 10rem;
  width: 10rem;
`
